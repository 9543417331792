<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-22 21:34:45
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-19 19:11:43
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\exception\404.vue
-->
<template>
  <exception-page type="404" />
</template>

<script>
import ExceptionPage from "./ExceptionPage";

export default {
  components: {
    ExceptionPage,
  },
};
</script>

<style scoped>
</style>